<template>
    <section>
      <div>
        <div  v-if="busqueda" class="bg-white shadow-md p-4 m-4 rounded-lg">
          <div>
            <p>
              Busqueda General:
            </p>
          </div>
          <div class="w-full">
            <div class="flex mb-2 w-full lg:w-3/12">
              <InputNumber @keyup.enter="buscarRecepcion" v-model="recepcionId" class="w-full mr-2" type="text" placeholder="" />
              <Button class="border-0 ml-2" v-on:keyup="alert('press')" @click="buscarRecepcion" icon="pi pi-search" style="width: 3.125rem;" />
            </div>
          </div>
          <p v-if="busquedaFallida" class="font-medium text-md">
            No existe ninguna recepcion registrada con este ID
          </p>
        </div>
        <div v-else>
          <div class="p-4">
            <Header />
            <br />
            <Table />
            <br />
            <Comments />
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
    import { ref, defineAsyncComponent, onMounted } from 'vue'
    import RecepcionService from '../../../../services/recepcion.service.js'
    import RecepcionStore from '../../../../store/recepcion.store'
    import { useRoute } from 'vue-router'

    export default {
    name: 'ComponenteRecepcion',
    components: {
      Header: defineAsyncComponent(
      () => import('./components/Header.vue')),
      Table: defineAsyncComponent(
      () => import('./components/Table.vue')),
      Comments: defineAsyncComponent(
      () => import('./components/Comments.vue'))
    },
    setup () {
      // Services
      const _RecepcionService = ref(new RecepcionService())
      const route = useRoute()
      // References
      const busqueda = ref(true)
      const busquedaFallida = ref(false)
      const recepcionId = ref(0)
      // Computed
      // Methods
      const buscarRecepcion = () => {
        _RecepcionService.value.find(recepcionId.value).then(({ data }) => {
          if (data.id) {
            busqueda.value = false
            busquedaFallida.value = false
            RecepcionStore.commit('setRecepcion', data)
          } else {
            busqueda.value = true
            busquedaFallida.value = true
          }
        })
      }
      onMounted(() => {
        if (route.params.id) {
          recepcionId.value = parseInt(route.params.id)
          buscarRecepcion()
        }
      })
      return {
        busqueda,
        buscarRecepcion,
        recepcionId,
        busquedaFallida
      }
    }
  }
  </script>

  <style scoped>
  </style>
